<template>
    <div class="alert-timeline__event" v-for="(event, index) in locations" :key="index">
        <div class="alert-timeline__event-line"></div>
        <div class="alert-timeline__event-info">
            <div class="alert-timeline__event-background-icon">
                <img src="@/assets/icons/fluent-emoji-high-contrast_sos-button.svg" v-if="event.eventId == 1" />
                <img src="@/assets/icons/eva_pin-fill.svg" v-if="event.eventId == 2" />
                <img src="@/assets/icons/bx_qr.svg" v-if="event.eventId == 3" />
                <img src="@/assets/icons/vuesax/linear/refresh-2-red.svg" v-if="event.eventId == 4" />
            </div>
            <div class="alert-timeline__event-text">
                <div class="alert-timeline__type-event">
                    {{ event.eventType }}
                </div>
                <div class="alert-timeline__event-location" v-if="event.locationName">
                    <img class="alert-timeline__event-text-icon" src="@/assets/icons/eva_pin-fill-white.svg" />
                    {{ event.locationName }} - {{ event.locationFloor }}
                </div>
                <div class="alert-report__event-location" v-else>
                    <img class="alert-report__event-text-icon" src="@/assets/icons/eva_pin-fill-white.svg" />
                    No Last Location
                </div>
                <div class="alert-report__event-location" v-if="event.lat">
                    <img class="alert-report__event-text-icon" src="@/assets/icons/vuesax/linear/global.svg" />
                    Lat: {{ event.lat }}
                </div>
                <div class="alert-report__event-location" v-if="event.lng">
                    <img class="alert-report__event-text-icon" src="@/assets/icons/vuesax/linear/global.svg" />
                    Lng: {{ event.lng }}
                </div>
                <div class="alert-timeline__event-time">
                    <img class="alert-timeline__event-text-icon" src="@/assets/icons/ri_time-line.svg" />
                    Date: {{ event.date }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, nextTick, ref } from 'vue'
import { associatesTrakerServices } from '../Services/AssociatesTrakingServices.js'
import { alertAssociatesTrackingEvent } from '@/sockets-events/AlertAssociatesTrackingEvent.js'

const { getAssociatesLocationsService } = associatesTrakerServices()
const { joinAssociatesTrackingEvent, leaveAssociatesTrackingEvent } = alertAssociatesTrackingEvent()
const props = defineProps(['alertId'])
const locations = ref([])
let map = null
let marker = null

onMounted(async () => {
    // * Join into tracking event
    joinTrackingEvent()

    // * Get associate locations
    await getAssociateLocations()

    // * Await to load all locations elements
    await nextTick()

    // * Update scroll on locations list
    updateScrollList()

    // * Set location object
    const location = locations.value.slice(-1)[0]
    const cords = { lat: parseFloat(location.lat), lng: parseFloat(location.lng) }

    // * Init Google Maps
    initMap(cords)
})

onBeforeUnmount(() => {
    // * Leave of tracking event
    leaveAssociatesTrackingEvent(props.alertId)
})

// * Join into panic alert event
function joinTrackingEvent() {
    joinAssociatesTrackingEvent(props.alertId)
        .listen(".location", async (position) => {
            // * Push new location
            locations.value.push(position)

            // * Await to load all locations elements
            await nextTick()

            // * Update scroll
            updateScrollList()

            // * Update marker on map
            updateMarker({ lat: parseFloat(position.lat), lng: parseFloat(position.lng) })
        })
}

// * Get associte locations
async function getAssociateLocations() {
    const response = await getAssociatesLocationsService(props.alertId)

    if (response.hasErrors) return console.error(`Error geting associate locations: ${response.message}`)

    locations.value = response.data
}

// * Update scroll on locations list
function updateScrollList() {
    // * Set scroll to bottom position
    const scroll = document.querySelector(".alert-timeline__events")
    scroll.scroll({ top: scroll.scrollHeight, behavior: "smooth" })
}

// * Init Google Maps
function initMap(cords) {
    const element = document.getElementById("map")

    map = new window.google.maps.Map(element, {
        zoom: 16,
        center: cords,
        mapTypeId: "terrain",
        disableDefaultUI: false,
    })

    // * Se agrega el marker en el mapa
    marker = new window.google.maps.Marker({
        position: cords,
        map,
        animation: window.google.maps.Animation.DROP,
    })
}

// * Update marker in Google Maps
function updateMarker(cords) {
    // * Center map on cords
    map.setCenter(cords)

    // * Update marker position
    marker.setPosition(cords)
}
</script>